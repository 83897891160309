html {
  background-attachment: fixed;
  background-size: cover;
}

body {
  height: 100%;
  margin: 0;
}

.busy-indicator {
  inset: 0;
  background-color: rgba(255, 255, 255, 0.5);
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 50;
}

.spinner {
  height: 64px;
  width: 64px;
  stroke: #7967ae;
  animation: rotator 0.9875s linear infinite;

  @keyframes rotator {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1turn);
    }
  }
}

.wrapper {
  animation: dashrotator 6s ease-in-out infinite;
  stroke: #7967ae;

  @keyframes dashrotator {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(270deg);
    }
  }
}

.track {
  stroke-width: 8px;
  stroke: #e3ddf4;
  fill: none;
  stroke-linecap: round;
}

.path {
  transform-origin: center;
  stroke-dasharray: 187;
  stroke-dashoffset: 187;
  stroke: #7967ae;
  stroke-width: 8px;
  animation: dash 6s cubic-bezier(0.42, 0.01, 0.58, 1) infinite;
  fill: none;
  stroke-linecap: round;

  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
      transform: rotate(0deg);
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
  
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
}
.footer-rtl {
  @media (min-width: 768px)
  {
      margin-inline-start: auto;
  }
}