// sass-lint:disable border-zero, quotes, zero-unit, no-important
// step up form css over here
.channel-collection-label {
    color: #4a4a4a;
    font-weight: 600;
    font-size: 14px;
}

.form-group-channel-collection {
    margin-bottom: 2px;
    padding: 15px;
    background-color: #fff;
}

.form-group-channel-collection-btn {
    margin-bottom: 10px;
    background-color: #fff;
}

.channel-collection-envelope {
    margin-right: 10px;
    margin-bottom: 2px;
}

.channel-collection-phone-input {
    border-radius: 0px !important;
    width: inherit !important;
}

.channel-collection-mobile-input-container {
    font-family: inherit !important;
    font-size: inherit !important;
}

.channel-collection-invalid-mobile-input {
    border: 1px solid #d40f0f !important;
    border-radius: 0px !important;
    width: inherit !important;
}

.channel-collection-invalid-email-input {
    border: 1px solid #d40f0f !important;
}

.channel-collection-country-flag-dropdown-error {
    border: 1px solid #d40f0f !important;
}

.boundary-margin {
    margin: 0px 50px 20px 50px;
    @media (max-width: $comp-responsive-breakpoint) {
		margin: 0px;
	  }
}

.info-circle {
    height: 4em;
    width: 4em;
    color: #1a8099;
    fill: #1a8099;
}

.check-circle {
    height: 4em;
    width: 4em;
    color: #119a0e;
    fill: #119a0e;
}

.cross-circle {
    height: 4em;
    width: 4em;
    color: #626262;
    fill:  #626262;
  }

.close-thin {
    height: 1.5em;
    width: 1.5em;
}

.channel_collection_warning_headline {
    color: #4a4a4a;
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.passcode {
    &.vdl-textbox {
        width: 150px;
        height: 44px;
        font-size: 30px;
        border: 1px solid #66afe9;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 #66afe9;
        text-align: center;
    }
}

.passcode_lavel_padding {
    padding-top: 6px;
    text-align: center;
    font-size: 14px;
  }

.passcode_title_color {
    color: #4a4a4a;
}

.passcode_margin_form {
    margin: 0 35px;
}

.resend_code_div {
  height: 78px;
  width: 470px;
  text-align: center;
  background-color: #fff;
}

.lineheight-form {
    &.vdl-checkbox {
        line-height: 16px;
        margin-bottom: 0px;
    }
}

.consent-padding {
    padding-left: 30px;
    line-height: 16px;
}

.flex-container {
    display: flex;
    justify-content: space-evenly;
}

.big-item {
    flex-basis: 56%;
}

.small-item {
    flex-basis: auto;
}
// sass-lint:disable-block force-attribute-nesting force-element-nesting force-pseudo-nesting
.emea-phone-input {
    .react-tel-input .flag {
        width: 16px;
        height: 11px;
        background-image: none !important;
    }
    .react-tel-input .selected-flag .iso2-class {
        text-transform: uppercase;
        position: relative;
        top: -4px;
        padding-left: 0px;
    }
    .react-tel-input .selected-flag .arrow {
        left: 22px !important;
        margin-top: -21px;
    }
    .react-tel-input .flag-dropdown {
        position: absolute;
        top: 2px;
        bottom: -1px;
        padding: 0;
        background-color: #cacaca;
        border: 1px solid #cacaca;
        border-radius: 3px 0 0 3px;
        display: inline;
    }
    .react-tel-input .selected-flag:hover,
        .react-tel-input {
        background-color: #cacaca !important;
    }
    .react-tel-input .country-list {
        .country {
            &:hover {
                background-color: #cff4fe !important; 
                border: 1px solid #1a8099 !important;
            }
            &:focus {
                background-color: #cff4fe;
                border: 1px solid #1a8099;
              }
            &.highlight {
                background-color: #1a8099 !important;
                color: #fff !important;
            }    
        }
    }
}

.react-tel-input input[type=tel] {
    text-align: left !important;
}
.flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
    display: none;
}

.form-control {
    position: relative;
    font-size: 14px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 10px !important;
    margin-left: 0;
    background: #fff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 300px;
    outline: none;
}


.special-label {
    display: none !important;
}
  
.delete-icon-align {
    text-align: end;
}

.usage-drop-padding {
    padding: 0px 15px;
}

.break-padding {
    padding-bottom: 20px;
}

.vb-padding-para {
    padding-bottom: 8px;
}