.reg-req-dropdown-icon {
    color: #f00;
}

.reg-form-pwd-error-label {
    color: #d40f0f;
}

.submit-button-align {
        width: auto; 
        text-align: center;
}

.form-group-data-collection {
  margin-bottom: 12px;
} 

.server-error-continue-bt {
    padding-top: 40px;
}

.qna-server-error-alert-message {
    font-weight: 600;
    text-align: center;
}

.sec-qa-label {
  font-weight: 400;
  color: #000;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.form-align {
      margin-bottom: 10px;
    }

.align-submit {
      margin-top: 38px;
      text-align: center;
  }

.safari-button-align {
    display: inline-block;
  }

.padding-align-inc-width {
      padding-left: 65px;
      padding-right: 65px;
      padding-bottom: 20px;
  }

.qna_class {
    width: 500px;
}

.qna-underline {
    border-bottom: 1px solid #bbb;
    padding: 5px;
}

.padding-inc-width {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 20px;
}
